import { IssuedDocumentService } from '@/modules/issued-document/issued-document-service';
import Errors from '@/shared/error/errors';

// action types
export const DO_FETCH = 'doFetch';

// mutation types
export const FETCH_SUCCESS = 'fetchSuccess';
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_FILTERS = 'setFilters';
export const SET_SORT = 'setSort';
export const SET_FILTER = 'doSetFilter';

const state = {
    rows: [],
    total: 0,
    storeFilters: {},
    currentPage: 1,
    fileDownloadAccessToken: null,
    storeSort: {sortBy: '', sortDesc: false},
};

const getters = {
    rows: (state) => state.rows || [],
    total: (state) => state.total,
    filters: (state) => state.storeFilters,
    currentPage: (state) => state.currentPage,
    fileDownloadAccessToken: (state) => state.fileDownloadAccessToken,
    sorting: (state) => state.storeSort,
};

const actions = {
    [DO_FETCH](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'issuedDocument/get', { root: true });
            if (payload?.sort && payload.sort.includes('document_series')) {
                const sortOrder = payload.sort.split('document_series');
                payload.sort = `${sortOrder[0]}document_series_number`;
            }
            let filters = { params: { ...payload } };
            if (payload && !payload?.sort) filters = { params: { ...payload , sort: '-issued_date'} };
            IssuedDocumentService.list(filters)
                .then(({ data, headers }) => {
                    const fileDownloadAccessToken = headers?.['x-file-download-access-token'] || '';
                    context.commit(FETCH_SUCCESS, {
                        rows: data.data,
                        total: data?.pagination?.total,
                        fileDownloadAccessToken,
                    });
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'issuedDocument/get', { root: true });
                });
        });
    },
    [SET_FILTER](context, { key, value }) {
        context.commit(SET_FILTERS, { key, value })
    },
};

const mutations = {
    [FETCH_SUCCESS](state, payload) {
        state.rows = payload.rows;
        state.total = payload.total;
        if (payload.fileDownloadAccessToken) {
            state.fileDownloadAccessToken = payload.fileDownloadAccessToken;
        }
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_FILTERS](state, { key, value }) {
        state.storeFilters[key] = value;
    },
    [SET_SORT](state, value) {
        state.storeSort = value;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
